var CryptoJS = require("crypto-js");

var key = "TheOtakuDiscussingSeriousTopics-NormieKeepOut";

export const Encrypt = (text) => {
  var encrypted = CryptoJS.AES.encrypt(text, key).toString();
  return encrypted;
};
export const Decrypt = (ciphertext) => {
  var bytes = CryptoJS.AES.decrypt(ciphertext, key);
  var decrypted = bytes.toString(CryptoJS.enc.Utf8);
  return decrypted;
};

import React, { useContext, useEffect, useState, useRef } from "react";
import {
  Box,
  InputBase,
  Typography,
  Button,
  CircularProgress,
} from "@mui/material";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { postChatDataWithImage } from "../../store/chatReducer";
import { SessionContext } from "../../sessionProvider";
import { Encrypt } from "../../util/aes";
import SendOutlinedIcon from "@mui/icons-material/SendOutlined";
import * as linkify from "linkifyjs";
import linkifyHtml from "linkify-html";
import _ from "lodash";
import { useDropzone } from "react-dropzone";

const options = {
  className: "urlInText",
  target: "_blank",
  rel: "noreferrer",
};

const img = {
  display: "block",
  width: "150px",
  height: "150px",
  objectFit: "cover",
  borderRadius: "10px",
};

const ImageUploader = (props) => {
  const { group_id } = props;
  const dispatch = useDispatch();
  const [files, setFiles] = useState([]);
  const chatInputRef = useRef();
  const { chatSentLoading } = useSelector((state) => state.chatReducer);
  const SessionContextValue = useContext(SessionContext);
  let loggedInUser = SessionContextValue.user;
  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      "image/png": [".png"],
      "image/jpg": [".jpg"],
      "image/jpeg": [".jpeg"],
      "image/gif": [".gif"],
      "image/webp": [".webp"],
      "image/svg": [".svg"],
    },
    onDrop: (acceptedFiles) => {
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
    },
  });

  const thumbs = files.map((file) => (
    <Box
      key={file.name}
      sx={{
        display: "inline-flex",
        borderRaidus: "10px",
        width: { xs: 100, sm: 150 },
        height: { xs: 100, sm: 150 },
      }}
      mb={2}
      mr={2}
    >
      <Box sx={{ display: "flex", minWidth: 0, overflow: "hidden" }}>
        <img
          src={file.preview}
          alt="uploaded images"
          style={img}
          // Revoke data uri after image is loaded
          onLoad={() => {
            URL.revokeObjectURL(file.preview);
          }}
        />
      </Box>
    </Box>
  ));

  useEffect(() => {
    // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
    return () => files.forEach((file) => URL.revokeObjectURL(file.preview));
  }, [files]);

  const onKeyPress = (e) => {
    //shift enter detect
    if (e.keyCode === 13 && !e.shiftKey) {
      e.preventDefault();
      formik.handleSubmit();
    }
  };

  const formik = useFormik({
    initialValues: {
      text: "",
      group_id: group_id,
    },
    onSubmit: (values) => {
      const { text, group_id } = values;
      const urlFound = linkify.find(text);
      const urlCovertedText = linkifyHtml(text, options);
      let encryptedText = Encrypt(urlCovertedText);
      const formData = new FormData();
      formData.append("text", encryptedText);
      formData.append("user_id", loggedInUser.userid);
      formData.append("group_id", group_id);
      formData.append("urlText", urlFound);
      if (files !== undefined && files.length !== undefined) {
        _.forEach(files, (file) => {
          formData.append("images", file);
        });
        dispatch(postChatDataWithImage(formData, loggedInUser.token));
      }
      formik.values.text = "";
    },
  });

  return (
    <Box p={2}>
      {files.length === 0 && (
        <Box
          {...getRootProps({ className: "dropzone" })}
          p={2}
          sx={{
            border: 1,
            height: "100px",
            borderRadius: "10px",
            borderColor: "text.light",
            borderStyle: "dashed",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <input {...getInputProps()} />
          <Typography variant="h2">
            Drag and drop some images here, or click to select images
          </Typography>
        </Box>
      )}
      {files.length > 0 && (
        <Box>
          <Typography variant="h2">{files.length} Images selected</Typography>
          <Box
            mt={2}
            sx={{
              display: "flex",
              flexDirection: "row",
              overflowX: "auto",
            }}
          >
            {thumbs}
          </Box>
          <Box
            sx={{
              width: "100%" - 20,
              height: "auto",
              paddingTop: "10px",
              borderTop: 1,
              borderColor: "grey.800",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                paddingLeft: "10px",
                paddingRight: "10px",
                width: "100%",
                bgcolor: "secondary.light",
                borderRadius: "5px",
              }}
            >
              <form>
                <InputBase
                  name="text"
                  inputRef={chatInputRef}
                  fullWidth
                  placeholder="Type Here..."
                  multiline={true}
                  sx={{ color: "text.light" }}
                  value={formik.values.text}
                  onKeyDown={(e) => onKeyPress(e)}
                  onChange={(e) => {
                    formik.handleChange(e);
                  }}
                />
              </form>
            </Box>

            <Button
              variant="text"
              sx={{
                minHeight: "33px",
                minWidth: "40px",
                padding: "5px",
                color: "text.light",
                flex: 1,
                bgcolor: "secondary.light",
                borderRadius: "7px",
                marginLeft: "5px",
                "&:hover": { bgcolor: "primary.light" },
              }}
              onClick={() => formik.handleSubmit()}
            >
              {chatSentLoading && (
                <CircularProgress sx={{ color: "text.light" }} size={20} />
              )}
              {!chatSentLoading && (
                <SendOutlinedIcon fontSize="small"></SendOutlinedIcon>
              )}
            </Button>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default ImageUploader;

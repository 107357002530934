import { createSlice } from "@reduxjs/toolkit";
import endpoint from "../util/endpoints";
import useApiRequest from "../util/useApiRequest";
import _ from "lodash";

export const chatReducer = createSlice({
  name: "chat",
  initialState: {
    members: null,
    chats: null,
    totalPages: 0,
    currentPage: 1,
    notifications: null,
    animes: null,
    games: null,
    chatSentLoading: false,
    openImageUploader: false,
  },
  reducers: {
    listMembers: (state, action) => {
      state.members = action.payload;
    },
    chats: (state, action) => {
      state.chats = action.payload.chats;
      state.totalPages = action.payload.totalPages;
      state.currentPage = action.payload.currentPage;
    },
    pushChats: (state, action) => {
      state.chats.unshift(action.payload);
    },
    chatSentLoading: (state, action) => {
      state.chatSentLoading = action.payload;
    },
    oldChats: (state, action) => {
      action.payload.chats.map((chat) => {
        return state.chats.push(chat);
      });
      state.totalPages = action.payload.totalPages;
      state.currentPage = action.payload.currentPage;
    },
    deletedChatUpdate: (state, action) => {
      const deletedId = action.payload.chatid;
      let newState = [...state.chats];
      _.forEach(newState, function (value, key) {
        if (value.id === deletedId) {
          newState[key]["deletedAt"] = new Date().toString();
        }
      });
      state.chats = newState;
    },
    notifications: (state, action) => {
      state.notifications = action.payload;
    },
    animes: (state, action) => {
      state.animes = action.payload.animeReviews;
    },
    games: (state, action) => {
      state.games = action.payload.gameReviews;
    },
    imageUploader: (state, action) => {
      state.openImageUploader = action.payload;
    },
  },
});

export default chatReducer.reducer;

export const {
  listMembers,
  chats,
  pushChats,
  chatSentLoading,
  oldChats,
  deletedChatUpdate,
  notifications,
  animes,
  games,
  imageUploader,
} = chatReducer.actions;

//get all members
export const listMemberData = (token) => async (dispatch) => {
  try {
    const response = await useApiRequest(
      "get",
      endpoint.members,
      null,
      { Authorization: "Bearer " + token },
      false
    );
    let data = response.data.users;
    dispatch(listMembers(data));
  } catch (e) {}
};
//post chat
export const postChatData =
  (text, urlFound, user_id, group_id, token) => async (dispatch) => {
    dispatch(chatSentLoading(true));
    try {
      await useApiRequest(
        "post",
        endpoint.chatPost,
        {
          text: text,
          urlText: urlFound,
          user_id: user_id,
          group_id: group_id,
        },
        { Authorization: "Bearer " + token }
      );
    } catch (e) {
      localStorage.removeItem("user");
      localStorage.removeItem("loginExpiryDate");
      window.location.href = "/";
    }
    dispatch(chatSentLoading(false));
  };
//get chat
export const getLatestChat = (group_id, token) => async (dispatch) => {
  try {
    const response = await useApiRequest(
      "get",
      endpoint.getLatestChat + `?group_id=${group_id}`,
      null,
      { Authorization: "Bearer " + token },
      false
    );
    let data = response.data;
    dispatch(chats(data));
  } catch (e) {}
};
//set new chat
export const setChat = (data) => async (dispatch) => {
  dispatch(pushChats(data));
};
//get old chat
export const getOldChat =
  (currentPage, totalPages, group_id, token) => async (dispatch) => {
    if (currentPage !== totalPages) {
      const page = currentPage + 1;
      try {
        const response = await useApiRequest(
          "get",
          endpoint.getLatestChat + `?page=${page}&group_id=${group_id}`,
          null,
          { Authorization: "Bearer " + token },
          false
        );
        let data = response.data;
        dispatch(oldChats(data));
      } catch (e) {}
    }
  };

//delete chat
export const deleteChat = (id, token) => async (dispatch) => {
  try {
    await useApiRequest(
      "delete",
      endpoint.deleteChat + "/" + id,
      null,
      { Authorization: "Bearer " + token },
      false
    );
  } catch (e) {}
};
//set deleted chat
export const setDeletedChat = (id) => async (dispatch) => {
  dispatch(deletedChatUpdate(id));
};

//set Notifications
export const setUserNotification = (noti_data) => async (dispatch) => {
  let data = noti_data.notifications;
  dispatch(notifications(data));
};

//get latest 10 anime reviews
export const getLatestAnimeReview = () => async (dispatch) => {
  try {
    const response = await useApiRequest(
      "get",
      endpoint.getLatestAnime,
      null,
      null,
      false
    );
    let data = response.data;
    dispatch(animes(data));
  } catch (e) {}
};

//get latest 10 game reviews
export const getLatestGameReview = () => async (dispatch) => {
  try {
    const response = await useApiRequest(
      "get",
      endpoint.getLatestGame,
      null,
      null,
      false
    );
    let data = response.data;
    dispatch(games(data));
  } catch (e) {}
};

//open image upload model
export const triggerImageUploadModel = (condition) => async (dispatch) => {
  dispatch(imageUploader(condition));
};

//post chat data with images
export const postChatDataWithImage = (formData, token) => async (dispatch) => {
  dispatch(chatSentLoading(true));
  try {
    await useApiRequest("post", endpoint.chatPost, formData, {
      "content-type": "multipart/form-data",
      Authorization: "Bearer " + token,
    });
  } catch (e) {
    localStorage.removeItem("user");
    localStorage.removeItem("loginExpiryDate");
    window.location.href = "/";
  }
  dispatch(imageUploader(false));
  dispatch(chatSentLoading(false));
};

import React, { useState, useEffect, useContext } from "react";
import { Box, Drawer } from "@mui/material";
import { NavBar, MemberBar } from "../../components";
import { useNavigate } from "react-router-dom";
import endpoint from "../../util/endpoints";
import UseApiRequest from "../../util/useApiRequest";
import { SessionContext } from "../../sessionProvider";
import _ from "lodash";

const MainLayout = (props) => {
  const { page, socket, group_id } = props;
  const [drawerOpen, setDrawerOpen] = useState({ left: false });
  const [onlineUsers, setOnlineUsers] = useState({});
  let navigate = useNavigate();
  const SessionContextValue = useContext(SessionContext);
  let loggedInUser = SessionContextValue.user;

  useEffect(() => {
    let loggedInExpireDate = SessionContextValue.expiry;
    //if no user data
    if (!loggedInUser || (loggedInUser && !loggedInUser.token)) {
      navigate("/");
      //check if token and expire date exist
    } else if (loggedInUser && loggedInExpireDate) {
      let now = new Date();
      loggedInExpireDate = new Date(loggedInExpireDate);
      //check if token is expired
      if (now > loggedInExpireDate) {
        localStorage.removeItem("user");
        localStorage.removeItem("loginExpiryDate");
        navigate("/");
      }
      //check with server if token if valid
      UseApiRequest("post", endpoint.checkToken, null, {
        Authorization: "Bearer " + loggedInUser.token,
      })
        .then((res) => {})
        .catch((err) => {
          localStorage.removeItem("user");
          localStorage.removeItem("loginExpiryDate");
          navigate("/");
        });
    }
  }, [navigate, loggedInUser, SessionContextValue.expiry]);

  useEffect(() => {
    socket.emit("online_user", loggedInUser);
    socket.on("online_user_list", (data) => {
      if (data.action === "online_user_list") {
        let onlineUsers = {};
        _.forEach(data.onlineUsers, (values) => {
          onlineUsers[values] = "true";
        });
        setOnlineUsers(onlineUsers);
      }
    });
    return () => socket.off("online_user_list");
  }, [socket, loggedInUser]);

  useEffect(() => {
    socket.emit("user_in_group", { loggedInUser, group_id });
  }, [socket, loggedInUser, group_id]);

  const toggleDrawer = (anchor, open) => (event) => {
    //toggle drawer
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setDrawerOpen({ [anchor]: open });
  };

  return (
    <>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          height: { xs: "100vh", sm: "100vh" },
          bgcolor: "primary.main",
          flexDirection: { xs: "column", sm: "row" },
          position: "relative",
        }}
      >
        {/* Nav Bar */}
        <NavBar
          onMenuClick={toggleDrawer("left", true)}
          page={page}
          socket={socket}
          group_id={group_id}
        />
        {/* Member Bar */}
        <Box
          sx={{
            height: "100vh",
            display: { xs: "none", lg: "block" },
          }}
        >
          <MemberBar
            room={page}
            loggedInUser={loggedInUser}
            socket={socket}
            onlineUsers={onlineUsers}
          />
        </Box>
        {/* Member Bar Mobile */}
        <Drawer
          anchor="left"
          open={drawerOpen["left"]}
          onClose={toggleDrawer("left", false)}
        >
          <Box
            sx={{
              height: "100vh",
              overflow: "none",
            }}
          >
            <MemberBar
              room={page}
              loggedInUser={loggedInUser}
              socket={socket}
              onlineUsers={onlineUsers}
            />
          </Box>
        </Drawer>
        {/* Childrens */}
        {props.children}
      </Box>
    </>
  );
};

export default MainLayout;

import React, { useContext, useEffect } from "react";
import { Box, IconButton } from "@mui/material";
import AnimeIcon from "../../assets/images/anime_girl.png";
import ChatBubble from "../../assets/images/chatbubble.png";
import GamePad from "../../assets/images/gamepad.png";
import GroupIcon from "./components/GroupIcon";
import { Link } from "react-router-dom";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import { SessionContext } from "../../sessionProvider";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setUserNotification } from "../../store/chatReducer";
import Nya from "../../assets/sound/nya.mp3";

const NavBar = (props) => {
  const { onMenuClick, socket, group_id } = props;
  const dispatch = useDispatch();
  const { notifications } = useSelector((state) => state.chatReducer);
  const SessionContextValue = useContext(SessionContext);
  let loggedInUser = SessionContextValue.user;
  let navigate = useNavigate();

  const onLogoutClick = () => {
    SessionContextValue.setLogout();
    navigate("/");
  };

  useEffect(() => {
    socket.on("notification", (data) => {
      if (data.action === "notificationUpdate") {
        dispatch(setUserNotification(data));
      }
    });
    return () => socket.off("notification");
  }, [socket, dispatch]);

  useEffect(() => {
    socket.on("notification", (data) => {
      if (data.action === "notificationUpdate" + loggedInUser.userid) {
        dispatch(setUserNotification(data));
        let notiTone = new Audio(Nya);
        notiTone.play();
      }
    });
    return () => socket.off("notification");
  }, [socket, dispatch, loggedInUser.userid]);

  return (
    <>
      {/* Nav Bar */}
      <Box
        sx={{
          width: { xs: "100%", sm: "80px" },
          height: { xs: "80px", sm: "100vh" },
          bgcolor: "primary.main",
          display: "flex",
          flexDirection: { xs: "row", sm: "column" },
          borderRight: { sm: 1, md: 1 },
          borderBottom: { xs: 1, sm: 0 },
          borderColor: { xs: "grey.800", sm: "grey.800", md: "grey.800" },
          position: { xs: "fixed", sm: "relative" },
          zIndex: 20,
        }}
      >
        <Box
          mt={2}
          sx={{
            height: "60px",
            width: { xs: "60px", sm: "100%" },
            display: { xs: "flex", lg: "none" },
            justifyContent: "center",
          }}
        >
          <IconButton onClick={onMenuClick}>
            <MenuOutlinedIcon fontSize="medium" sx={{ color: "text.light" }} />
          </IconButton>
        </Box>
        <Box
          sx={{
            width: { xs: "100%", sm: "80px" },
            height: { xs: "80px", sm: "100%" },
            display: "flex",
            flexDirection: { xs: "row", sm: "column" },
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Link to="/anime">
            <GroupIcon
              image={AnimeIcon}
              page={group_id === 1 ? "true" : null}
              notifications={notifications}
              group_id={1}
            />
          </Link>
          <Link to="/game">
            <GroupIcon
              image={GamePad}
              tilted="true"
              page={group_id === 2 ? "true" : null}
              notifications={notifications}
              group_id={2}
            />
          </Link>
          <Link to="/general">
            <GroupIcon
              image={ChatBubble}
              page={group_id === 3 ? "true" : null}
              notifications={notifications}
              group_id={3}
            />
          </Link>
        </Box>
        <Box
          mt={2}
          sx={{
            height: "60px",
            width: { xs: "60px", sm: "100%" },
            display: { xs: "flex" },
            justifyContent: "center",
          }}
        >
          <IconButton onClick={onLogoutClick}>
            <LogoutOutlinedIcon
              fontSize="medium"
              sx={{ color: "text.light" }}
            />
          </IconButton>
        </Box>
      </Box>
    </>
  );
};

export default NavBar;

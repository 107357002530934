import { Button } from "@mui/material";
import { styled } from "@mui/material/styles";

const CustomizedButton = styled(Button)(
  ({ theme }) => `
  background: ${theme.palette.primary.dark},
  color: ${theme.palette.text.light},
  :hover{
    background: ${theme.palette.primary.light},
  }
`
);

const IconBtn = styled(Button)(
  ({ theme }) => `
  min-width: 40px,
  height: 40px,
  border-radius: "50%",
  background: "transparent,
  background: ${theme.palette.primary.dark},
  color: ${theme.palette.text.light},
  :hover{
    background: ${theme.palette.primary.light},
    box-shadow: "none",
  }
`
);

const CustomButton = (props) => {
  const { btnName, onClick, fullWidth, iconBtn, disabled } = props;

  return iconBtn ? (
    <IconBtn
      variant="contained"
      type="submit"
      size="small"
      disabled={disabled}
      fullWidth={fullWidth}
      onClick={onClick}
    >
      {btnName}
    </IconBtn>
  ) : (
    <CustomizedButton
      variant="contained"
      type="submit"
      size="small"
      disabled={disabled}
      fullWidth={fullWidth}
      onClick={onClick}
    >
      {btnName}
    </CustomizedButton>
  );
};

export default CustomButton;

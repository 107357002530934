const endpoints = {
  login: "/auth/login", //post
  checkToken: "/auth/token", //post
  members: "/members", //get
  chatPost: "/chat/post", //post
  deleteChat: "/chat/delete", //delete
  getLatestChat: "/chat/getLatestChat", //get
  getLatestAnime: "/anime/getLatest", //get
  getLatestGame: "/game/getLatest", //get
};

export default endpoints;

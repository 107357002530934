import React from "react";
import { Box, Typography, Card } from "@mui/material";
import api_routes from "../../util/routes";

const ReviewCard = (props) => {
  const { cardTitle, datas, type } = props;
  return (
    <Card
      sx={{
        width: "100% - 20px",
        bgcolor: "secondary.dark",
        padding: "20px",
      }}
    >
      <Typography mt={3} mb={3} variant="h1" sx={{ textAlign: "center" }}>
        {cardTitle}
      </Typography>
      {datas &&
        datas.map((data, index) => (
          <Box
            mb={1}
            sx={{ display: "flex", justifyContent: "space-between" }}
            key={index}
          >
            <Box>
              <a
                href={
                  type === "anime"
                    ? api_routes.THEOTAKUREVIEW +
                      `anime/${data.id}/${data.jptitle.replace(/\s+/g, "-")}` +
                      "-review"
                    : api_routes.THEOTAKUREVIEW +
                      `game/${data.id}/${data.title.replace(/\s+/g, "-")}` +
                      "-review"
                }
                target="_blank"
                rel="noreferrer"
              >
                <Typography
                  variant="body1"
                  key={index}
                  sx={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    display: "-webkit-box",
                    WebkitLineClamp: "1",
                    WebkitBoxOrient: "vertical",
                  }}
                >
                  {type === "anime" ? data.jptitle : data.title}
                </Typography>
              </a>
            </Box>
            <Box
              sx={{
                bgcolor: "secondary.main",
                width: "35px",
                height: "35px",
                borderRadius: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography variant="h3">{data.finalscore}</Typography>
            </Box>
          </Box>
        ))}
    </Card>
  );
};

export default ReviewCard;

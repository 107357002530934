import { createTheme } from "@mui/material/styles";

const main = "#181818";
const mainDark = "#202020";
const mainLight = "#333333";
const second = "#520613";
const secondDark = "#37474f";
const secondLight = "#595E67";
const white = "#ffffff";
const black = "#252525";
const gray = "#BEBEBE";
const smokeyWhite = "#DCDCDC";
const error = "#c61a09";

export const theme = createTheme({
  //color palette;
  palette: {
    primary: {
      main: main,
      dark: mainDark,
      light: mainLight,
    },
    secondary: {
      main: second,
      dark: secondDark,
      light: secondLight,
    },
    error: {
      main: error,
    },
    background: {
      default: main,
    },
    text: {
      default: black,
      light: white,
      dark: gray,
    },
  },
  //typography settings
  typography: {
    h1: {
      fontWeight: 600,
      fontSize: "30px",
      color: white,
    },
    h2: {
      fontWeight: 600,
      fontSize: "20px",
      color: white,
    },
    h3: {
      fontWeight: 600,
      fontSize: "16px",
      color: white,
    },
    h4: {
      fontWeight: 600,
      fontSize: "14px",
      color: white,
    },
    h5: {
      fontWeight: 600,
      fontSize: "25px",
      color: white,
    },
    h6: {
      fontWeight: 600,
      fontSize: "20px",
      color: white,
    },
    subtitle1: {
      fontSize: "16px",
      color: white,
    },
    subtitle2: {
      fontSize: "14px",
      color: white,
    },
    body1: {
      fontSize: "16px",
      color: white,
    },
    body2: {
      fontSize: "12px",
      color: white,
    },
    button: {
      fontSize: "16px",
      color: white,
    },
  },
  overrides: {
    MuiInputLabel: {
      root: {
        color: smokeyWhite,
        "&$focused": {
          color: white,
          fontWeight: 600,
        },
      },
    },
    MuiInput: {
      root: {
        color: smokeyWhite,
        fontSize: 16,
        paddingTop: 10,
      },
      underline: {
        "&:hover:not($disabled):before": {
          borderBottomColor: gray,
        },
        "&:before": {
          borderBottomColor: "#9e9e9e",
        },
        "&:after": {
          borderBottomColor: white,
        },
      },
    },
  },
});

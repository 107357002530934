//development
// const api_routes = {
//   API_ROUTE: "http://127.0.0.1:3000",
//   IMG_ROUTE: "http://127.0.0.1:8000/images/",
//   THEOTAKUREVIEW: "https://theotaku.review/",
// };
// live
const api_routes = {
  API_ROUTE: "https://chatapi.theotaku.review",
  IMG_ROUTE: "https://api.theotaku.review/images/",
  THEOTAKUREVIEW: "https://theotaku.review/",
};

export default api_routes;

import React, { useContext } from "react";
import { Box, Typography, Avatar, Button, Popover } from "@mui/material";
import api_routes from "../../util/routes";
import moment from "moment";
import MoreVertOutlinedIcon from "@mui/icons-material/MoreVertOutlined";
import { useDispatch } from "react-redux";
import { deleteChat } from "../../store/chatReducer";
import { SessionContext } from "../../sessionProvider";
import Parser from "html-react-parser";
import { styled } from "@mui/material/styles";

const ScrappedImg = styled((props) => <img {...props} alt={props.alt} />)`
  width: 100%;
  position: relative;
  top: 5px;
  object-fit: cover;
  object-position: center;
`;

const UploadedImg = styled((props) => <img {...props} alt={props.alt} />)`
  width: 100%;
  position: relative;
  top: 5px;
  object-fit: cover;
  object-position: center;
  border-radius: 10px;
  margin-bottom: 10px;
`;

const ChatBubble = (props) => {
  const {
    text,
    date,
    own,
    username,
    profileImg,
    chatid,
    deletedAt,
    scrapped_data,
    chatImgs,
  } = props;
  const SessionContextValue = useContext(SessionContext);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const dispatch = useDispatch();
  let open = Boolean(anchorEl);
  const id = open ? "editPopOver" : undefined;
  let loggedInUser = SessionContextValue.user;

  const handlePopOver = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopOverClose = () => {
    setAnchorEl(null);
  };

  const handleDelete = () => {
    setAnchorEl(null);
    dispatch(deleteChat(chatid, loggedInUser.token));
  };

  return (
    <Box
      mb={4}
      sx={{
        width: "100%",
        display: "flex",
        justifyContent: own ? "flex-end" : "flex-start",
      }}
    >
      <Box
        sx={{
          width: {
            xs: "75%",
            sm: "55%",
            md: "35%",
            lg: "35%",
            xl: "35%",
          },
          height: "auto",
          padding: "15px",
          display: "flex",
          flexDirection: "column",
          bgcolor: own ? "secondary.main" : "secondary.dark",
          opacity: deletedAt ? 0.6 : 1,
          borderRadius: "20px",
        }}
      >
        <Box
          pb={2}
          mb={2}
          sx={{
            display: "flex",
            alignItems: "center",
            borderBottom: 1,
            borderColor: "grey.500",
          }}
        >
          <Box>
            <Avatar
              src={api_routes.IMG_ROUTE + profileImg}
              alt="profile image"
              sx={{ width: 50, height: 50 }}
            />
          </Box>
          <Box ml={2} sx={{ width: "100%" }}>
            <Typography variant="h3">{username}</Typography>
          </Box>
          {own && (
            <Box>
              {!deletedAt && (
                <Button variant="text" onClick={handlePopOver}>
                  <MoreVertOutlinedIcon sx={{ color: "text.light" }} />
                </Button>
              )}
              <Popover
                sx={{
                  ".MuiPopover-paper": { bgcolor: "transparent" },
                }}
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handlePopOverClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
              >
                <Box sx={{ bgcolor: "primary.main" }}>
                  <Button
                    variant="text"
                    onClick={handleDelete}
                    sx={{ color: "text.light", bgcolor: "primary.main" }}
                  >
                    Delete
                  </Button>
                </Box>
              </Popover>
            </Box>
          )}
        </Box>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          {!deletedAt && (
            <Typography
              variant="body1"
              align="left"
              sx={{ whiteSpace: "pre-wrap" }}
            >
              {Parser(text)}
            </Typography>
          )}
          {!deletedAt &&
            chatImgs &&
            chatImgs.map((chat, index) => (
              <UploadedImg
                key={index}
                src={api_routes.API_ROUTE + "/" + chat.path}
              />
            ))}
          {!deletedAt && chatImgs.length === 0 && scrapped_data && (
            <Box
              mt={1}
              p={1}
              sx={{ borderLeft: 5, borderColor: "secondary.light" }}
            >
              <Typography variant="h3">{scrapped_data.title}</Typography>
              <Typography variant="body1" sx={{ marginTop: "5px" }}>
                {scrapped_data.description}
              </Typography>
              <a href={scrapped_data.url} target="_blank" rel="noreferrer">
                <ScrappedImg src={scrapped_data.image} />
              </a>
            </Box>
          )}
          {deletedAt && (
            <Typography variant="body1" sx={{ fontStyle: "italic" }}>
              *This Chat Was Deleted*
            </Typography>
          )}
          <Box mt={1}>
            <Typography align="right" variant="body2">
              {moment().diff(date, "days") > 14
                ? moment(date).format("DD/MM/YYYY, h:mm a")
                : moment(date).fromNow()}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ChatBubble;

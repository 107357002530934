import React, { useEffect, useContext } from "react";
import { Box, Container } from "@mui/material";
import { ProfileCard, ReviewCard } from "../../components";
import { MainLayout } from "../../layout";
import { useDispatch, useSelector } from "react-redux";
import { SessionContext } from "../../sessionProvider";
import api_routes from "../../util/routes";
import {
  getLatestAnimeReview,
  getLatestGameReview,
} from "../../store/chatReducer";

const Profile = (props) => {
  const { socket } = props;
  const dispatch = useDispatch();
  const { animes, games } = useSelector((state) => state.chatReducer);
  const SessionContextValue = useContext(SessionContext);
  let loggedInUser = SessionContextValue.user;

  useEffect(() => {
    dispatch(getLatestAnimeReview());
    dispatch(getLatestGameReview());
  }, [dispatch]);

  return (
    <MainLayout group_id={0} socket={socket}>
      {/* Body */}
      <Container
        sx={{
          marginTop: { xs: "90px", sm: "10px" },
          marginBottom: "10px",
          height: "100vh - 10px",
          position: "relative",
          overflowY: "auto",
        }}
      >
        <Box>
          <Box
            sx={{
              width: "98%",
              margin: "1%",
              position: "relative",
              float: "left",
            }}
          >
            <ProfileCard
              cardTitle="Your Profile"
              profileImg={api_routes.IMG_ROUTE + loggedInUser.profileImg}
              name={loggedInUser.name}
              role={loggedInUser.role}
              desc={loggedInUser.desc}
            />
          </Box>
          <Box
            sx={{
              width: { xs: "98%", md: "48%" },
              margin: "1%",
              position: "relative",
              float: "left",
            }}
          >
            <ReviewCard
              cardTitle="Lastest Anime Reviews"
              datas={animes}
              type="anime"
            />
          </Box>
          <Box
            sx={{
              width: { xs: "98%", md: "48%" },
              margin: "1%",
              position: "relative",
              float: "left",
            }}
          >
            <ReviewCard
              cardTitle="Lastest Game Reviews"
              datas={games}
              type="game"
            />
          </Box>
        </Box>
      </Container>
    </MainLayout>
  );
};

export default Profile;

import React, { useEffect, useRef, useState, useContext } from "react";
import { Box, Button, CircularProgress } from "@mui/material";
import { ChatBubble, ChatBox } from "../../components";
import { MainLayout } from "../../layout";
import { useDispatch, useSelector } from "react-redux";
import {
  getLatestChat,
  setChat,
  getOldChat,
  setDeletedChat,
  triggerImageUploadModel,
} from "../../store/chatReducer";
import { SessionContext } from "../../sessionProvider";
import { Decrypt } from "../../util/aes";

const Game = (props) => {
  const { socket } = props;
  const [stopScroll, setStopScroll] = useState(false);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const { chats, totalPages, currentPage } = useSelector(
    (state) => state.chatReducer
  );
  const messagesEndRef = useRef(null);
  const SessionContextValue = useContext(SessionContext);
  let loggedInUser = SessionContextValue.user;

  useEffect(() => {
    dispatch(getLatestChat(2, loggedInUser.token));
  }, [dispatch, loggedInUser.token]);

  useEffect(() => {
    socket.on("chatMessage" + 2, (data) => {
      if (data.action === "chatPost" + 2) {
        dispatch(setChat(data.chats.chatRes));
      }
    });
    return () => socket.off("chatMessage" + 2);
  }, [socket, dispatch]);

  useEffect(() => {
    socket.on("chatDeleted", (data) => {
      setStopScroll(true);
      if (data.action === "chatDeleted") {
        dispatch(setDeletedChat(data.chatId));
      }
    });
    return () => socket.off("chatDeleted");
  }, [socket, dispatch]);

  useEffect(() => {
    if (!stopScroll) {
      messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    }
  }, [chats, stopScroll]);

  const onScroll = () => {
    if (messagesEndRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = messagesEndRef.current;
      if (scrollTop + clientHeight === scrollHeight) {
        setStopScroll(false);
      }
    }
  };

  const handleLoadOlderMsg = (currentPage, totalPages) => {
    setLoading(true);
    let group_id = 2;
    setStopScroll(true);
    dispatch(getOldChat(parseInt(currentPage), totalPages, group_id));
    setLoading(false);
  };

  const handleImageDragEnter = (e) => {
    e.preventDefault();
    dispatch(triggerImageUploadModel(true));
  };

  return (
    <MainLayout page="Game" group_id={2} socket={socket}>
      {/* Chat Body */}
      <Box
        onDragEnter={handleImageDragEnter}
        sx={{
          width: "100%",
          height: "100vh",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box
          onScroll={onScroll}
          sx={{
            padding: "20px",
            width: "100%" - 20,
            height: "100vh",
            overflow: "auto",
            marginTop: { xs: "80px", sm: "0px" },
          }}
        >
          {currentPage < totalPages && (
            <Box
              mb={4}
              sx={{ width: "100%", display: "flex", justifyContent: "center" }}
            >
              <Button
                onClick={() => handleLoadOlderMsg(currentPage, totalPages)}
                variant="contained"
                size="medium"
                sx={{
                  color: "text.dark",
                  bgcolor: "primary.light",
                  borderRadius: "12px",
                }}
              >
                {loading ? (
                  <CircularProgress sx={{ color: "text.light" }} size={20} />
                ) : (
                  "Load older messages"
                )}
              </Button>
            </Box>
          )}
          {chats &&
            chats
              .slice(0)
              .reverse()
              .map((chat, index) => (
                <ChatBubble
                  key={index}
                  chatid={chat.id}
                  text={Decrypt(chat.text)}
                  own={loggedInUser.userid === chat.userId ? true : false}
                  date={chat.createdAt}
                  username={chat.user.name}
                  profileImg={chat.user.profile_image}
                  deletedAt={chat.deletedAt}
                  scrapped_data={JSON.parse(chat.scrapped_data)}
                  chatImgs={chat.chatimages}
                />
              ))}
          <Box ref={messagesEndRef}></Box>
        </Box>
        {/* Chat box */}
        <ChatBox group_id={2} socket={socket} />
      </Box>
    </MainLayout>
  );
};

export default Game;

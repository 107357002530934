import React, { useEffect } from "react";
import { Box, Typography } from "@mui/material";
import MemberBox from "./components/MemberBox";
import api_routes from "../../util/routes";
import { useDispatch, useSelector } from "react-redux";
import { listMemberData } from "../../store/chatReducer";
import { useNavigate } from "react-router-dom";

const MemberBar = (props) => {
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const { members } = useSelector((state) => state.chatReducer);
  const { room, loggedInUser, onlineUsers } = props;

  useEffect(() => {
    dispatch(listMemberData(loggedInUser.token));
  }, [dispatch, loggedInUser.token]);

  const goToProfile = () => {
    navigate("/profile");
  };

  return (
    <>
      {/* Member Bar */}
      <Box
        sx={{
          width: "310px",
          height: "100%",
          bgcolor: "primary.light",
          overflow: "auto",
        }}
      >
        <Box p={2} sx={{ overflow: "none" }}>
          {room && (
            <Box mb={2}>
              <Typography variant="h1">Room: {room}</Typography>
            </Box>
          )}
          {/* Your Boxes */}
          <Box mb={2}>
            <Typography variant="h2">You</Typography>
          </Box>
          <MemberBox
            onClick={goToProfile}
            image={api_routes.IMG_ROUTE + loggedInUser.profileImg}
            name={loggedInUser.name}
            role={loggedInUser.role}
            isuseronline={"true"}
          />
          <Box mb={2} mt={2}>
            <Typography variant="h2">Members</Typography>
          </Box>
          {/* Member Boxes */}
          {members &&
            members.map((member, index) => (
              <MemberBox
                key={index}
                image={api_routes.IMG_ROUTE + member.profile_image}
                name={member.name}
                role={member.role.name}
                desc={member.desc}
                isuseronline={onlineUsers[member.id]}
              />
            ))}
        </Box>
      </Box>
    </>
  );
};

export default MemberBar;

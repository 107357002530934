import React, { useContext, useEffect, useState, useRef } from "react";
import {
  Box,
  InputBase,
  Typography,
  Button,
  Popover,
  CircularProgress,
  Modal,
} from "@mui/material";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { postChatData, triggerImageUploadModel } from "../../store/chatReducer";
import { SessionContext } from "../../sessionProvider";
import _ from "lodash";
import { Encrypt } from "../../util/aes";
import EmojiEmotionsOutlinedIcon from "@mui/icons-material/EmojiEmotionsOutlined";
import SendOutlinedIcon from "@mui/icons-material/SendOutlined";
import ImageOutlinedIcon from "@mui/icons-material/ImageOutlined";
import data from "@emoji-mart/data";
import Picker from "@emoji-mart/react";
import * as linkify from "linkifyjs";
import linkifyHtml from "linkify-html";
import ImageUploader from "../ImageUploader";

const ChatBox = (props) => {
  const { group_id, socket } = props;
  const dispatch = useDispatch();
  const SessionContextValue = useContext(SessionContext);
  let loggedInUser = SessionContextValue.user;
  const { chatSentLoading, openImageUploader } = useSelector(
    (state) => state.chatReducer
  );
  const [typing, setTyping] = useState({});
  const [timer, setTimer] = useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openImageModal, setImageModal] = useState(false);
  let openEmoji = Boolean(anchorEl);
  const id = openEmoji ? "emojiPopOver" : undefined;
  const chatInputRef = useRef();

  const options = {
    className: "urlInText",
    target: "_blank",
    rel: "noreferrer",
  };

  const formik = useFormik({
    initialValues: {
      text: "",
      group_id: group_id,
    },
    onSubmit: (values) => {
      const { text, group_id } = values;
      if (text) {
        const urlFound = linkify.find(text);
        const urlCovertedText = linkifyHtml(text, options);
        let encryptedText = Encrypt(urlCovertedText);
        formik.values.text = "";
        dispatch(
          postChatData(
            encryptedText,
            urlFound,
            loggedInUser.userid,
            group_id,
            loggedInUser.token
          )
        );
      }
    },
  });

  const timeoutStopTyping = () => {
    const data = { name: SessionContextValue.user.name, group_id: group_id };
    socket.emit("user_typing_stopped", data);
  };

  const typingHandler = () => {
    const data = { name: SessionContextValue.user.name, group_id: group_id };
    socket.emit("user_typing", data);
    //wait for typing to finish before calling top typing function
    clearTimeout(timer);
    const newTimer = setTimeout(() => {
      timeoutStopTyping();
    }, 1500);
    setTimer(newTimer);
  };

  useEffect(() => {
    socket.on("typing", (data) => {
      if (data.action === "typing" + group_id) {
        delete data.typingUsers[SessionContextValue.user.name];
        setTyping(data.typingUsers);
      }
    });

    return () => socket.off("typing" + group_id);
  }, [socket, SessionContextValue.user.name, group_id]);

  const onKeyPress = (e) => {
    //shift enter detect
    if (e.keyCode === 13 && !e.shiftKey) {
      e.preventDefault();
      formik.handleSubmit();
    }
  };

  const handlePopOver = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopOverClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (openImageUploader) {
      setImageModal(true);
    } else {
      setImageModal(false);
    }
  }, [openImageUploader]);

  const handleImageDragLeave = (e) => {
    e.preventDefault();
    dispatch(triggerImageUploadModel(false));
  };

  const handleImageModalOpen = () => {
    setImageModal(true);
    dispatch(triggerImageUploadModel(true));
  };
  const handleImageModalClose = () => {
    setImageModal(false);
    dispatch(triggerImageUploadModel(false));
  };

  const emojiSelectHandler = (data) => {
    let text_msg = formik.values.text + "" + data.native;
    formik.values.text = text_msg;
    setAnchorEl(null);
    setTimeout(() => {
      chatInputRef.current.focus();
    }, 100);
  };

  return (
    <>
      {typing && !_.isEmpty(typing) && _.size(typing) > 1 && (
        <Box
          sx={{
            display: "flex",
            width: "100% - 10px",
            marginRight: "10px",
            marginBottom: "5px",
            justifyContent: "flex-end",
          }}
        >
          <Typography>Multiple members are typing...</Typography>
        </Box>
      )}
      {typing && !_.isEmpty(typing) && _.size(typing) === 1 && (
        <Box
          sx={{
            display: "flex",
            width: "100% - 10px",
            marginRight: "10px",
            marginBottom: "5px",
            justifyContent: "flex-end",
          }}
        >
          <Typography>{typing[Object.keys(typing)[0]]} is typing...</Typography>
        </Box>
      )}
      <Box
        sx={{
          width: "100%" - 20,
          height: "auto",
          padding: "15px",
          bgcolor: "primary.main",
          borderTop: 1,
          borderColor: "grey.800",
          display: "flex",
          alignItems: "center",
        }}
      >
        <Button
          variant="text"
          sx={{
            minHeight: "33px",
            minWidth: "40px",
            padding: "5px",
            color: "text.light",
            flex: 1,
            bgcolor: "secondary.light",
            borderRadius: "7px",
            marginRight: "5px",
            "&:hover": { bgcolor: "primary.light" },
          }}
          onClick={handleImageModalOpen}
        >
          <ImageOutlinedIcon fontSize="small"></ImageOutlinedIcon>
        </Button>
        <Modal
          open={openImageModal}
          onClose={handleImageModalClose}
          disableAutoFocus={true}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            border: 0,
          }}
          onDragLeave={handleImageDragLeave}
        >
          <Box
            sx={{
              bgcolor: "primary.main",
              borderRadius: "10px",
              width: { xs: "300px", sm: "400px", md: "35%", lg: "50%" },
            }}
          >
            <ImageUploader group_id={group_id} />
          </Box>
        </Modal>
        <Button
          variant="text"
          sx={{
            minHeight: "33px",
            minWidth: "40px",
            padding: "5px",
            color: "text.light",
            flex: 1,
            bgcolor: "secondary.light",
            borderRadius: "7px",
            marginRight: "5px",
            "&:hover": { bgcolor: "primary.light" },
          }}
          onClick={handlePopOver}
        >
          <EmojiEmotionsOutlinedIcon fontSize="small"></EmojiEmotionsOutlinedIcon>
        </Button>
        <Popover
          id={id}
          open={openEmoji}
          anchorEl={anchorEl}
          onClose={handlePopOverClose}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          sx={{
            ".MuiPopover-paper": { bgcolor: "transparent" },
          }}
        >
          <Picker data={data} onEmojiSelect={emojiSelectHandler} theme="dark" />
        </Popover>
        <Box
          pr={1}
          pl={1}
          sx={{
            width: "100%",
            bgcolor: "secondary.light",
            borderRadius: "5px",
          }}
        >
          <form onSubmit={formik.handleSubmit}>
            <InputBase
              name="text"
              inputRef={chatInputRef}
              fullWidth
              placeholder="Type Here..."
              multiline={true}
              sx={{ color: "text.light" }}
              value={formik.values.text}
              onKeyDown={(e) => onKeyPress(e)}
              onChange={(e) => {
                typingHandler();
                formik.handleChange(e);
              }}
            />
          </form>
        </Box>
        <Button
          variant="text"
          sx={{
            minHeight: "33px",
            minWidth: "40px",
            padding: "5px",
            color: "text.light",
            flex: 1,
            bgcolor: "secondary.light",
            borderRadius: "7px",
            marginLeft: "5px",
            "&:hover": { bgcolor: "primary.light" },
          }}
          onClick={() => formik.handleSubmit()}
        >
          {chatSentLoading && (
            <CircularProgress sx={{ color: "text.light" }} size={20} />
          )}
          {!chatSentLoading && (
            <SendOutlinedIcon fontSize="small"></SendOutlinedIcon>
          )}
        </Button>
      </Box>
    </>
  );
};

export default ChatBox;

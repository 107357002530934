import axios from "axios";
import api_routes from "./routes";

export default async function useApiRequest(
  method,
  url,
  data = null,
  props,
  withCred
) {
  let finalUrl = api_routes.API_ROUTE + url;
  return axios(finalUrl, {
    method,
    data,
    withCredentials: withCred,
    headers: props,
  });
}

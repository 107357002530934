import React, { useState } from "react";
import { Box, Typography, Avatar, Badge, Modal } from "@mui/material";
import { ProfileCard } from "../../../components";
import { styled } from "@mui/material/styles";

const StyledBadge = styled(Badge)(({ theme, isuseronline }) => ({
  "& .MuiBadge-badge": {
    backgroundColor: isuseronline === "true" ? "#44b700" : "#757575",
    color: isuseronline === "true" ? "#44b700" : "#757575",
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    width: "10px",
    height: "10px",
    "&::after": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      borderRadius: "100%",
      border: `1px solid ${theme.palette.secondary.light}`,
    },
  },
}));

const MemberBox = (props) => {
  const { image, name, role, desc, isuseronline, onClick } = props;
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  return (
    <>
      <Box
        onClick={onClick ? onClick : handleOpen}
        mb={2}
        sx={{
          width: "100%-10px",
          height: "100px",
          bgcolor: "secondary.light",
          padding: { xs: "5px" },
          borderRadius: { xs: "10px" },
          display: "flex",
          alignItems: "center",
          "&:hover": { cursor: "pointer" },
        }}
      >
        <Box ml={1}>
          <StyledBadge
            overlap="circular"
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            variant="dot"
            isuseronline={isuseronline}
          >
            <Avatar
              src={image}
              alt="profile image"
              sx={{ width: 70, height: 70 }}
            />
          </StyledBadge>
        </Box>
        <Box ml={2}>
          <Typography variant="h3">{name}</Typography>
          <Typography variant="subtitle2">Role: {role}</Typography>
        </Box>
      </Box>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        disableAutoFocus={true}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          border: 0,
        }}
      >
        <Box
          sx={{
            width: { xs: "90%", md: "50%", lg: "30%", xl: "30%" },
          }}
        >
          <ProfileCard
            cardTitle="Profile"
            profileImg={image}
            name={name}
            role={role}
            desc={desc}
          />
        </Box>
      </Modal>
    </>
  );
};

export default MemberBox;

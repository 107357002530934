import React, { useState, useEffect, useContext } from "react";
import {
  Box,
  Card,
  CardContent,
  TextField,
  Typography,
  CircularProgress,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import Logo from "../../assets/images/chatlogo.png";
import { CustomButton } from "../../components";
import { useFormik } from "formik";
import * as yup from "yup";
import endpoint from "../../util/endpoints";
import UseApiRequest from "../../util/useApiRequest";
import { SessionContext } from "../../sessionProvider";
import { useNavigate } from "react-router-dom";

const LogoImg = styled((props) => <img {...props} alt={props.alt} />)`
  width: 80%;
  height: auto;
  margin-left: 10%;
`;

const LoginTextField = styled(TextField)(
  ({ theme }) => `  /* default */
.MuiInput-input{
  color: ${theme.palette.text.light};
}
.MuiInput-underline:before {
  border-color: ${theme.palette.text.light};
}
& label {
  color: ${theme.palette.text.light};
}
/* hover */
&& .MuiInput-underline:hover:before {
  border-bottom: 1px solid ${theme.palette.text.light};
}
/* focused */
.MuiInput-underline:after {
  border-bottom: 2px solid ${theme.palette.text.light};
}
& label.Mui-focused {
  color: ${theme.palette.text.light};
},
`
);

const validationSchema = yup.object({
  email: yup
    .string("Enter your email")
    .email("Enter a valid email")
    .required("Email is required"),
  password: yup
    .string("Enter your password")
    .min(6, "Password should be of minimum 6 characters")
    .required("Password is required"),
});

const Login = () => {
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);
  const userContext = useContext(SessionContext);
  let navigate = useNavigate();

  useEffect(() => {
    let loggedInUser = JSON.parse(localStorage.getItem("user"));
    let loggedInExpireDate = localStorage.getItem("loginExpiryDate");
    //if use got token
    if (loggedInUser && loggedInUser.token && loggedInExpireDate) {
      let now = new Date();
      loggedInExpireDate = new Date(loggedInExpireDate);
      //if date is valid
      if (now < loggedInExpireDate) {
        //check if jwt is valid with the server
        UseApiRequest("post", endpoint.checkToken, null, {
          Authorization: "Bearer " + loggedInUser.token,
        })
          .then((res) => {
            navigate("/profile");
          })
          .catch((err) => {});
      }
    }
  }, [navigate]);

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      remember: false,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      const { email, password, remember } = values;
      setError(null);
      setLoading(true);
      UseApiRequest("post", endpoint.login, { email, password })
        .then((res) => {
          setLoading(false);
          userContext.setUserData(res.data, remember);
          navigate("/profile");
        })
        .catch((err) => {
          setLoading(false);
          setError(err.response.data.error);
        });
    },
  });
  return (
    <Box
      sx={{
        width: "100%",
        height: "100vh",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        bgcolor: "primary.main",
      }}
    >
      <Card sx={{ width: 320, bgcolor: "secondary.light" }}>
        <CardContent>
          <Box
            mt={1}
            mb={1}
            pb={1}
            pt={1}
            sx={{ borderBottom: 1, borderColor: "#d3d3d3" }}
          >
            <LogoImg src={Logo} alt="The Otaku Review Logo" />
            <Typography mt={1} variant="h2" align="center">
              Message Board Login
            </Typography>
            <Box mt={1}>
              <Typography variant="body2" align="center">
                This is an invite only message board currently still in early
                alpha stage and might never turn into anything else apart from
                being a hobby project.
              </Typography>
            </Box>
          </Box>
          <form onSubmit={formik.handleSubmit}>
            <Box sx={{ width: "100%" }} mt={3}>
              <LoginTextField
                fullWidth={true}
                size="small"
                id="email"
                label="Email"
                InputLabelProps={{ shrink: true }}
                name="email"
                variant="standard"
                value={formik.values.email}
                onChange={formik.handleChange}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
              />
            </Box>
            <Box mt={2} mb={1}>
              <LoginTextField
                fullWidth={true}
                size="small"
                id="password"
                label="Password"
                type="Password"
                InputLabelProps={{ shrink: true }}
                name="password"
                variant="standard"
                value={formik.values.password}
                onChange={formik.handleChange}
                error={
                  formik.touched.password && Boolean(formik.errors.password)
                }
                helperText={formik.touched.password && formik.errors.password}
              />
            </Box>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    sx={{
                      color: "text.light",
                      "&.Mui-checked": {
                        color: "text.light",
                      },
                    }}
                    name="remember"
                    value={formik.values.remember}
                    onChange={formik.handleChange}
                  />
                }
                label="Remember me for 30 days"
              />
            </FormGroup>
            {error && (
              <Typography variant="subtitle2" sx={{ color: "error.main" }}>
                {error}
              </Typography>
            )}
            <Box mt={3}>
              <CustomButton
                btnName={
                  loading ? (
                    <CircularProgress sx={{ color: "text.light" }} size={20} />
                  ) : (
                    "Login"
                  )
                }
                fullWidth={true}
              />
            </Box>
          </form>
        </CardContent>
      </Card>
    </Box>
  );
};

export default Login;

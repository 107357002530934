import React, { useState } from "react";

export const SessionContext = React.createContext();

const SessionProvider = (props) => {
  let loggedUser = "";
  let expireDate = "";
  if (typeof window !== "undefined") {
    loggedUser = JSON.parse(localStorage.getItem("user")) || "";
    expireDate = localStorage.getItem("loginExpiryDate") || "";
  }
  const [user, setUser] = useState(loggedUser);
  const [expiry, setExpiryDate] = useState(expireDate);

  const setUserData = (userData, remember) => {
    let date = new Date();
    if (remember) {
      date.setDate(date.getDate() + 30);
      localStorage.setItem("user", JSON.stringify(userData));
      localStorage.setItem("loginExpiryDate", date);
    } else {
      date.setDate(date.getDate() + 1);
      localStorage.setItem("user", JSON.stringify(userData));
      localStorage.setItem("loginExpiryDate", date);
    }
    setUser(userData);
    setExpiryDate(date);
  };

  const setLogout = () => {
    localStorage.removeItem("user");
    localStorage.removeItem("loginExpiryDate");
    setUser(null);
  };

  return (
    <SessionContext.Provider value={{ user, expiry, setUserData, setLogout }}>
      {props.children}
    </SessionContext.Provider>
  );
};

export default SessionProvider;

import React from "react";
import { Box, Typography, Avatar, Card } from "@mui/material";

const ProfileCard = (props) => {
  const { cardTitle, profileImg, name, role, desc } = props;
  return (
    <Card
      sx={{
        width: "100% - 20px",
        bgcolor: "secondary.dark",
        padding: "20px",
      }}
    >
      <Typography mt={3} mb={3} variant="h1" sx={{ textAlign: "center" }}>
        {cardTitle}
      </Typography>
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <Avatar
          src={profileImg}
          alt="profile image"
          sx={{ width: 150, height: 150 }}
        />
      </Box>
      <Typography mt={3} mb={1} variant="h2" sx={{ textAlign: "center" }}>
        {name}
      </Typography>
      <Typography mb={3} variant="subtitle2" sx={{ textAlign: "center" }}>
        {role}
      </Typography>
      <Typography mt={2} mb={2} variant="body1">
        {desc}
      </Typography>
    </Card>
  );
};

export default ProfileCard;

import React from "react";
import { Box, Typography } from "@mui/material";
import { styled, darken } from "@mui/material/styles";

const GroupBtn = styled("img")(
  ({ tiltedcheck }) => `
  width: 40px;
  height: 40px;
  object-fit: contain;
  object-position: center;
  transform: ${tiltedcheck === "true" ? "rotate(-20deg)" : "rotate(0deg)"};
`
);

const RoundedButton = styled(Box)(
  ({ theme, page }) => `
  background: ${
    page ? theme.palette.secondary.main : theme.palette.secondary.light
  };
  width: 50px;
  height: 50px;
  border-radius: 10%;
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  :hover {
    background: ${darken(theme.palette.secondary.main, 0.3)};
  }
  ${theme.breakpoints.down("sm")} {
    margin-bottom: 0px;
    margin-right: 10px;
  }
`
);

const GroupIcon = (props) => {
  let { image, tilted, page, notifications, group_id } = props;
  return (
    <RoundedButton page={page} sx={{ position: "relative" }}>
      {notifications &&
        notifications.map((noti, index) => (
          <Box key={index}>
            {noti.chatgroupId === group_id && noti.noti_count !== 0 && (
              <Box
                sx={{
                  width: "15px",
                  height: "15px",
                  bgcolor: "#d32f2f",
                  position: "absolute",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  zIndex: 10,
                  borderRadius: "100%",
                  padding: "3px",
                  top: "-7px",
                  right: "-7px",
                }}
              >
                <Typography sx={{ fontSize: 12, textAlign: "center" }}>
                  {noti.noti_count <= 99 ? noti.noti_count : "∞"}
                </Typography>
              </Box>
            )}
          </Box>
        ))}
      <GroupBtn alt="anime group chat" src={image} tiltedcheck={tilted} />
    </RoundedButton>
  );
};

export default GroupIcon;

import { Routes, Route } from "react-router-dom";
import "./App.css";
import Anime from "./screens/anime/Anime";
import Game from "./screens/game/Game";
import General from "./screens/general/General";
import Login from "./screens/login/Login";
import Profile from "./screens/profile/Profile";
import api_routes from "./util/routes";
import io from "socket.io-client";
const socket = io.connect(api_routes.API_ROUTE);

function App() {
  return (
    <Routes>
      <Route path="/anime" element={<Anime socket={socket} />} />
      <Route path="/game" element={<Game socket={socket} />} />
      <Route path="/general" element={<General socket={socket} />} />
      <Route path="/profile" element={<Profile socket={socket} />} />
      <Route path="/" element={<Login />} />
    </Routes>
  );
}

export default App;
